.ant-input-number-input {
    /*font-family: bahnschrift, serif !important;*/
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    border-bottom: 0;
}
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.04);
}
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected:hover {
    background-color: rgba(0, 0, 0, 0.06);
}

a.ant-typography {
    color: rgba(0, 0, 0, 0.85);
}

.ant-tooltip-inner a.ant-typography {
    color: rgba(255,255, 255, 1);
}

.ant-pro-layout .ant-pro-sider .ant-layout-sider-children {
    /*padding-inline: 1px !important;*/
    /*padding-top: 44px;*/
}
.ant-layout-sider-children {
    padding-top: 24px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin-inline: 8px;
}

.ant-pro-table-alert {
    margin-top: -60px;
    width: 50%;
}
/**/
.MyDraggableTreeTable .ant-tree-draggable-icon,
.MyDraggableTreeTable .ant-tree-switcher_open,
.MyDraggableTreeTable .ant-tree-switcher {
    line-height: 36px !important;
    opacity: 1 !important;
}

.MyDraggableTreeTable .ant-tree-draggable-icon svg{
    fill: #808080;
}

.ant-table-cell .ant-image, .ant-table-cell .ant-image .ant-image-img {
    width: unset !important;
    max-height: 60px;
    max-width: 106px;
}

/*.ant-drawer .ant-drawer-content {*/
/*    display: block;*/
/*}*/
.ant-drawer .ant-drawer-footer {
    /*flex-shrink: 0;*/
    padding: 16px 24px 24px 24px;
    border-top: 1px solid rgba(5, 5, 5, 0.06)
}


.MyItemRequired::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 12px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}
.MyWangEditorHiddenText {
    width: 1px !important;
}