@font-face {
  font-family: bahnschrift;
  src:url("./bahnschrift.ttf")
}
@font-face {
  font-family: FontEmoji;
  src:url("./NotoColorEmoji.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: bahnschrift, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  /*
  Duration has been increased by 4x from the original version for demo purposes.
  */
  transition: opacity 1000ms ease-in;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.country-icon {
  font-size: 18px;
  font-family: FontEmoji, serif;
}
