
.center {text-align: center}
.right {float: right;text-align: right;}

.red {color: #e54546}
.blue {color: #46a9ff}
.green {color: #77d472}
.grey {color: rgba(128,128,128,0.5)}
.normal {color: #333333}
.bold {font-weight: bold}

.high-light {
    color: #e54546;
    font-size: 13px;
    font-weight: 600;
}
.deleted {
    text-decoration: line-through;
    color: #808080;
}
.treeTableToolbar {
    /*margin-bottom: 16px;*/
}
.treeTableHead {
    margin: 16px 0 4px 0;
    background-color: rgba(128,128,128,0.1);
    height: 36px;
    border-bottom: 1px solid rgba(128,128,128,0.2);
    border-radius: 8px 8px  0 0;
    font-weight: 600;
}
.treeTableHead div {
    padding: 8px 0 ;
}
.treeTableHead div span {
    display: block;
    padding: 0 16px ;
    border-right: 1px solid rgba(128,128,128,0.3);
}
.treeTableNode {
    height: 36px;
    border-bottom: 1px solid rgba(128,128,128,0.2);
    line-height: 36px;
}
.treeTableNode div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/*.ant-drawer-close {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    width: 60px;*/
/*    height: 60px;*/
/*}*/

/*WangEditor*/
.editorLabel{
    padding: 8px 12px;
    width: 15%;
    text-align: right;
}
.editorTools {
    padding-left: 24px;
}
.editorContainer {
    border: 1px solid #ddd;
    /*background: #3a9685;*/
    z-index: 100;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px
}
.w-e-bar-item {
    padding: 0 !important;
    font-size: 12px;
}

.log2account {
    color: #ee9900;
}